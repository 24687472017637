import React, { useState } from "react";

import type { MouseEventHandler } from "react";
import type { Image } from "../../../../client/ContentApi/models";

import { ActiveImage } from "./ActiveImage";

type GalleryModalProps = {
  images: Image[];
  closeModal: MouseEventHandler<HTMLElement>;
  modalRef?: React.RefObject<HTMLDivElement>;
};

const GalleryModal: React.FC<GalleryModalProps> = ({
  images,
  closeModal,
  modalRef
}) => {
  const [activeIndex, setActive] = useState(0);

  const hasImageNavs = images.length > 1;

  const handleActive = (e: React.MouseEvent, operator: number) => {
    e.preventDefault();
    e.stopPropagation();

    let newActiveIndex = activeIndex;

    operator ? newActiveIndex++ : newActiveIndex--;

    if (activeIndex <= 0 && operator === -1) {
      newActiveIndex = images.length - 1;
    }

    if (newActiveIndex > images.length - 1) {
      newActiveIndex = 0;
    }

    setActive(newActiveIndex);
  };

  return (
    <div className="modal-content" onClick={closeModal} ref={modalRef}>
      {images.map((image, i) => {
        return (
          <ActiveImage key={i} image={image} isActive={i === activeIndex} />
        );
      })}
      {hasImageNavs && (
        <>
          <button
            className="modal-content-prev"
            onClick={e => handleActive(e, -1)}
          />
          <button
            className="modal-content-next"
            onClick={e => handleActive(e, 1)}
          />
        </>
      )}
    </div>
  );
};

export { GalleryModal };
