import React, { useContext, useEffect, useRef } from "react";

import type { CSSProperties } from "react";

import { PremiumLockContext } from "../../../../containers/PremiumLockContainer";
import { NoActiveSubscriptionPopup } from "./NoActiveSubscriptionPopup";
import { login } from "../../../../containers/GlobalStateContainer/userState/actions/login";
import { useGlobalStore } from "../../../../containers/GlobalStateContainer/store";

const getLockStyling = (isLocked: boolean) =>
  isLocked
    ? ({
        "--modalBackground": "hsla(0, 0%, 0%, 0.6)",
        "--takeClicks": "auto",
        "--showLockContainer": "1"
      } as CSSProperties)
    : ({
        "--modalBackground": "transparent",
        "--takeClicks": "none",
        "--showLockContainer": "0"
      } as CSSProperties);

const PremiumModal: React.FC = () => {
  const { isLoggedIn, salesposter } = useGlobalStore();
  const {
    show,
    isLocked,
    lockScreen,
    canMinimize,
    noActivePopup,
    closeNoActivePopup
  } = useContext(PremiumLockContext);

  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show && isLocked) {
      document.body.classList.add("noscroll");
    }

    const bodyHasNoscroll = document.body.classList.contains("noscroll");

    if (!isLocked && bodyHasNoscroll) {
      document.body.classList.remove("noscroll");
    }
  }, [isLocked, show]);

  const extendLock = isLocked && (!noActivePopup || !isLoggedIn);

  return show ? (
    <div
      className="modal"
      style={getLockStyling((noActivePopup && isLoggedIn) || isLocked)}
    >
      <div className="modal-bg modal-premium--bg" />
      {isLoggedIn && noActivePopup ? (
        <NoActiveSubscriptionPopup close={closeNoActivePopup} />
      ) : null}
      <article
        style={getLockStyling(extendLock)}
        className="modal-premium modal-premium-content modal-premium-content--bg"
        tabIndex={-1}
        ref={modalContentRef}
      >
        <div className="modal-premium-content--container">
          {isLocked && canMinimize && (
            <div
              className="close"
              tabIndex={0}
              role="button"
              onKeyDown={() => {}}
              onClick={() => lockScreen(false)}
            >
              <span className="left" />
              <span className="right" />
            </div>
          )}
          <div className="modal-premium-content--text">
            <h3 className="title">För den nya tidens affärsmänniska</h3>
            <div
              className={` block modal-premium-content--${
                extendLock ? "visible" : "hidden"
              }`}
            >
              <p className="block modal-premium-content--mobile">
                Sveriges bästa bevakning av börs-, bolag- och marknadsnyheter.
                Dessutom får du upplåst innehåll från världens främsta
                affärsmedier, dagliga expertkommentarer och aktieanalyser. Och
                mycket mera.
              </p>
              <p className="block modal-premium-content--desktop">
                Sveriges bästa bevakning av börs-, bolag- och marknadsnyheter.
                Dessutom får du upplåst innehåll från världens främsta
                affärsmedier, dagliga expertkommentarer och aktieanalyser. Och
                mycket mera.
              </p>
            </div>
          </div>

          <div className="modal-premium-content-button--container">
            <a href="https://om.omni.se/ekonomi">
              <button className="btn modal-premium-content-button--primary">
                {salesposter?.additional_data?.web_ekonomi_offer_title ||
                  "Du behöver logga in för att läsa innehållet"}
              </button>
            </a>
            <button
              onClick={login}
              className={`btn modal-premium-content-button--secondary ${
                extendLock ? "visible" : "hidden"
              }`}
            >
              Redan medlem? Logga in
            </button>
          </div>
        </div>
      </article>
    </div>
  ) : null;
};

export { PremiumModal };
