import React, { useContext } from "react";
import Image from "next/image";

import type { MouseEventHandler } from "react";

import { TrackingContext } from "../../../contexts/TrackingContext";
import ArrowImg from "../../../../public/img/salesposter/arrow.png";
import { getImageSizes } from "../../../helpers";

const sizes = getImageSizes({ web: "350px", tablet: "52vw", mobile: "65vw" });

type PremiumInformationModalProps = {
  closeModal: MouseEventHandler<HTMLElement>;
  modalRef?: React.RefObject<HTMLDivElement>;
};

const PremiumInformationModal: React.FC<PremiumInformationModalProps> = ({
  closeModal,
  modalRef
}) => {
  const { salesPosterReadMore } = useContext(TrackingContext);

  return (
    <div
      data-testid="premium-information-popup"
      className="modal-content modal-content-premium--popup"
      ref={modalRef}
    >
      <div className="modal-premium-popup--image">
        <Image
          className="salesposter-featurebox-image"
          src={ArrowImg}
          alt="Salesposter"
          sizes={sizes}
        />
      </div>
      <h1 className="modal-premium-popup--title">
        Läs Omni med färre betalväggar
      </h1>
      <p className="modal-premium-popup--body">
        Det ska vara enkelt att fördjupa sig i nyheterna och läsa mer! Tack vare
        Omnis samarbeten med en lång rad medier kan du som Omni Mer-medlem läsa
        artiklar som annars är låsta.
      </p>
      <div className="modal-premium-popup--buttons">
        <button
          onClick={closeModal}
          className="btn btn--secondary modal-premium-popup--button"
        >
          Stäng
        </button>
        <a
          href="https://om.omni.se/omni-mer?utm_content=omni%20key%20pop-up"
          className=" btn btn--primary modal-premium-popup--button"
          onClick={() => salesPosterReadMore()}
        >
          Upptäck Omni Mer
        </a>
      </div>
    </div>
  );
};

export { PremiumInformationModal };
