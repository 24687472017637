import React from "react";

import type { Image } from "../../../../client/ContentApi/models";

import { imageSrc } from "../../../../lib/image";
import {
  Img,
  pickWatermarkByFormat
} from "../../../Article/Resources/ImageResource";
import { getImageSizes } from "../../../../helpers";

const sizes = getImageSizes({ web: "100vw" });

type ActiveImageProps = {
  image: Image;
  isActive: boolean;
};

const ActiveImage: React.FC<ActiveImageProps> = ({ image, isActive }) => {
  const caption = image.caption?.value ? `${image.caption?.value} ` : "";

  return (
    <div
      className={`modal-content-fixedratio${
        image.format === "1x1" ? "-square" : ""
      }
    ${!isActive ? " modal-content-fixedratio--hidden" : ""}`}
    >
      <Img
        watermark={
          image.watermarks
            ? pickWatermarkByFormat(
                image.watermarks,
                image.format === "1x1" ? image.format : "2x1"
              )
            : undefined
        }
        imageUrl={imageSrc(image, "expanded", true)}
        caption={caption}
        lazy={false}
        sizes={sizes}
        className="modal-content-image modal-content-image--fallback"
      />
      <div className="modal-content-caption">
        {image?.caption?.value} {image?.byline?.title}
      </div>
    </div>
  );
};

export { ActiveImage };
