import React from "react";

import HeartSVG from "../../../../assets/svg/heart.svg";
import CloseSVG from "../../../../assets/svg/close.svg";

type NoActiveSubscriptionPopupProps = {
  close: () => void;
};

const NoActiveSubscriptionPopup: React.FC<NoActiveSubscriptionPopupProps> = ({
  close
}) => {
  return (
    <div className="no-active-subscription">
      <div
        className="close"
        onClick={close}
        onKeyDown={e => e.keyCode === 13 && close()}
        role="button"
        tabIndex={0}
      >
        <CloseSVG />
      </div>
      <p className="center-block">
        <HeartSVG />
      </p>
      <h2>Inget aktivt medlemskap!</h2>
      <h3>
        Är du inte medlem än? Nya medlemmar får testa Omni Ekonomi gratis.
      </h3>
      <div className="buy-button">
        <a href="https://om.omni.se/ekonomi">
          <button className="btn modal-premium-content-button--primary">
            Bli medlem!
          </button>
        </a>
      </div>
      <p>
        Har ditt medlemskap löpt ut?
        <br /> Förnya det under dina kontoinställningar eller{" "}
        <a href="https://omni.se/minsida">hantera ditt medlemskap här.</a>
      </p>
      <p>
        Köpte du ditt medlemskap i iOS-appen?
        <br /> Se till att logga in med ditt Schibsted-konto i iOS-appen där du
        har köpt ditt medlemskap. Sedan kan du läsa Omni Ekonomi på flera
        enheter genom att logga in med samma konto.
      </p>
      <p>
        Har du några frågor? <br />
        Kontakta oss på
        <a href="mailto:medlem@omniekonomi.se"> medlem@omniekonomi.se</a>
      </p>
    </div>
  );
};

export { NoActiveSubscriptionPopup };
