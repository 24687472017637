/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useContext } from "react";

import type { Article, Resource, Url } from "../../../client";

import { useModal } from "../../../hooks/useModal";
import { PremiumInformationModal } from "../modals/PremiumInformationModal";
import { TrackingContext } from "../../../contexts/TrackingContext";
import { getConfig } from "../../../config";
import { UseLocalStorage } from "../../../hooks/UseLocalStorage";

type PremiumInformationButtonProps = {
  children: React.ReactNode;
  url: string;
  title: string;
  article: Article;
  resource: Url;
};

const isNormalUrl = (url: Url) =>
  !url.data?.custom_properties?.schema?.match(
    /^tweet|page:wikipedia|video:youtube$/
  );

const indexForTracking = (resources: Resource[], urlResource: Url) => {
  const urlResources = resources?.filter(
    res => res.type === "Url" && isNormalUrl(res)
  );
  const urlIndex = urlResources?.indexOf(urlResource);
  return urlIndex + 1 || undefined;
};

const PremiumInformationButton: React.FC<PremiumInformationButtonProps> = ({
  children,
  url,
  title,
  article,
  resource
}) => {
  const { openExternal } = useContext(TrackingContext);
  const { ModalWrapper, isOpen, ref, open, close } = useModal();

  const { NEXT_PUBLIC_MER } = getConfig();

  const hasPopupEnabled = resource?.show_paywall_popup && NEXT_PUBLIC_MER;

  const showPopup = useCallback((): boolean => {
    const timeframe = Number(UseLocalStorage().get("omnikeytimeframe"));
    const now = Date.now();
    const oneDay = 60 * 60 * 24 * 1000;

    if (timeframe && now - timeframe < oneDay) {
      return false;
    }
    UseLocalStorage().set("omnikeytimeframe", now.toString());
    return true;
  }, []);

  const modal = isOpen ? (
    <ModalWrapper>
      <PremiumInformationModal closeModal={close} modalRef={ref} />
    </ModalWrapper>
  ) : null;

  return (
    <>
      <a
        href={url}
        target="_blank"
        title={title}
        className="resource--link-link a a--primary"
        rel={`noopener ${article.sponsor ? "sponsored" : "noreferrer"}`}
        onClick={event => {
          openExternal(
            resource,
            article,
            indexForTracking(article?.resources, resource)
          );
          if (hasPopupEnabled && !isOpen && showPopup()) {
            setTimeout(() => {
              open(event);
            }, 1000);
          }
        }}
      >
        {children}
      </a>
      {modal}
    </>
  );
};

export { PremiumInformationButton };
