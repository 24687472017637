import React, { useContext, useEffect, useState } from "react";

import type { Article, SalesposterAdditionalData } from "../../client";

import { ArticleContext } from "../../contexts/ArticleContext";
import { TrackingContext } from "../../contexts/TrackingContext";
import { getConfig } from "../../config";
import { login } from "../../containers/GlobalStateContainer/userState/actions/login";
import { useGlobalStore } from "../../containers/GlobalStateContainer/store";

const getUtmContent = (referrer: string) => {
  if (!referrer) {
    return undefined;
  }

  try {
    const url = new URL(referrer);

    switch (url.pathname) {
      case "/":
        return "top news";
      case "/senaste":
        return "latest news";
      default:
        return url.pathname;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const getCategoryAndTopics = (article: Article) => {
  return [article.category.title]
    .concat(article.tags.map(tag => tag.title))
    .toString();
};

const getUtmParams = (article: Article, referrer: string) => {
  const searchParams = new URLSearchParams();

  const params = {
    utm_source: "in-article salesposter",
    content_id: article.article_id,
    utm_term: getCategoryAndTopics(article),
    utm_content: getUtmContent(referrer),
    source_uri: window?.location?.href
  };

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      searchParams.append(key, value);
    }
  }

  return searchParams.toString();
};

export const ArticleSalesposter = () => {
  const [utmParams, setUtmParams] = useState("");
  const { NEXT_PUBLIC_MER } = getConfig();
  const { article } = useContext(ArticleContext);
  const { referrer, setSalesPosterReferrerArticle } =
    useContext(TrackingContext);
  const { isLoggedIn, salesposter } = useGlobalStore();
  const { salesPosterLoginClick, salesPosterReadMore, ctaFeaturesClick } =
    useContext(TrackingContext);

  const isLicensed = article.format === "licensed";
  const introPostfix = isLicensed ? "licensed" : "owncontent";

  useEffect(() => {
    setSalesPosterReferrerArticle(article);
    setUtmParams(getUtmParams(article, referrer));
  }, [article, referrer, setSalesPosterReferrerArticle]);

  if (!NEXT_PUBLIC_MER) {
    return null;
  }

  const redirectURL = `https://om.omni.se/omni-mer${
    utmParams ? `?${utmParams}` : ""
  }`;

  return (
    <div className="salesposter salesposter-inarticle salesposter-inarticle--container">
      <div className="salesposter-section salesposter-inarticle-section">
        <div className="salesposter-intro--container">
          <h2 className="salesposter-intro salesposter-intro--title">
            {
              salesposter?.additional_data[
                `article_intro_title_${introPostfix}` as keyof SalesposterAdditionalData
              ]
            }
          </h2>
          <p className="salesposter-intro salesposter-intro--body">
            {
              salesposter?.additional_data[
                `article_intro_body_${introPostfix}` as keyof SalesposterAdditionalData
              ]
            }
          </p>

          <a
            href={redirectURL}
            className="salesposter-intro salesposter--more"
            onClick={() => salesPosterReadMore()}
          >
            Upptäck Omni Mer{" "}
            <span className="salesposter-intro--arrow">&#8594;</span>
          </a>

          <div className="salesposter-button--container">
            {!isLoggedIn && (
              <button
                onClick={() => {
                  salesPosterLoginClick();
                  login();
                }}
                className="salesposter-button button salesposter-button--secondary"
              >
                Logga in
              </button>
            )}
            <a
              href={redirectURL}
              className="salesposter-button button salesposter-button--primary"
              onClick={() => {
                ctaFeaturesClick("Bli medlem");
              }}
            >
              Bli medlem
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
