import React from "react";

import { useModal } from "../../../hooks/useModal";
import { CogwheelModal } from "../modals/CogwheelModal";

type CogwheelButtonProps = {
  creativeId?: number;
};

const CogwheelButton: React.FC<CogwheelButtonProps> = ({ creativeId }) => {
  const { ModalWrapper, isOpen, ref, open, close } = useModal();

  const modal = isOpen ? (
    <ModalWrapper>
      <CogwheelModal
        closeModal={close}
        modalRef={ref}
        creativeId={creativeId}
      />
    </ModalWrapper>
  ) : null;

  return (
    <>
      <i
        className="icon icon--settings"
        onClick={open}
        role="button"
        tabIndex={0}
      />
      {modal}
    </>
  );
};

export { CogwheelButton };
