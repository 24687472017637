import React from "react";

import type { Image, Watermark } from "../../../client";

import { useModal } from "../../../hooks/useModal";
import { Img } from "../../Article/Resources/ImageResource";
import { GalleryModal } from "../modals/GalleryModal/GalleryModal";
import { getImageSizes } from "../../../helpers";

const sizes = getImageSizes({ web: "688px", tablet: "100vw" });

type ModalGalleryButtonProps = {
  images: Image[];
  watermark: Watermark | undefined;
  imageUrl: string;
  lazy: boolean;
};

const GalleryButton: React.FC<ModalGalleryButtonProps> = ({
  images,
  watermark,
  imageUrl,
  lazy
}) => {
  const { ModalWrapper, isOpen, ref, open, close } = useModal();

  const caption = images[0].caption?.value
    ? `${images[0].caption?.value} `
    : "";

  const modal = isOpen ? (
    <ModalWrapper>
      <GalleryModal images={images} closeModal={close} modalRef={ref} />
    </ModalWrapper>
  ) : null;

  return (
    <>
      <span
        className="resource-zoomable"
        onClick={open}
        role="button"
        tabIndex={0}
      >
        <Img
          watermark={watermark}
          imageUrl={imageUrl}
          lazy={lazy}
          caption={caption}
          sizes={sizes}
        />
      </span>
      {modal}
    </>
  );
};

export { GalleryButton };
