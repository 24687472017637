import React, { useEffect, useState } from "react";

import type { MouseEventHandler } from "react";

import { useGlobalStore } from "../../../containers/GlobalStateContainer/store";

type CogwheelModalProps = {
  closeModal: MouseEventHandler<HTMLElement>;
  modalRef?: React.RefObject<HTMLDivElement>;
  creativeId?: number;
};

const CogwheelModal: React.FC<CogwheelModalProps> = ({
  closeModal,
  modalRef,
  creativeId
}) => {
  const { isLoggedIn } = useGlobalStore();
  const [privacyUrl, setPrivacyUrl] = useState("");
  const creativeIdComponent = creativeId && (
    <p className="modal-cogwheel-footer-creative">
      Annonsens ID-nummer: {creativeId}
    </p>
  );

  useEffect(() => {
    const createPrivacyUrl = async () => {
      if (isLoggedIn) {
        setPrivacyUrl("https://privacysettings.schibsted.com/");
      } else {
        const sdk = window._pulseSdk;
        const pulseEnvId = await sdk?.getEnvironmentId();
        setPrivacyUrl(
          `https://privacysettings.schibsted.com/?environment_id=${pulseEnvId}`
        );
      }
    };

    createPrivacyUrl();
  }, []);

  return (
    <div className="modal-content">
      <div ref={modalRef} className="modal-cogwheel">
        <div className="modal-cogwheel-x">
          <i
            className="icon icon--x"
            onClick={closeModal}
            role="button"
            tabIndex={0}
          />
        </div>
        <h3>Varför ser du denna annons?</h3>
        <p>
          Du ser denna annons eftersom sajten du besöker till stor del
          finansieras av annonsintäkter. Vissa annonser ser du för att vi tror
          att de kan intressera dig. Detta antagande baserar vi på din tidigare
          aktivitet på Schibsted Medias sajter och appar.
        </p>

        <p>
          <a
            href={privacyUrl}
            className="modal-cogwheel-link a"
            target="_blank"
            rel="noreferrer"
          >
            Hantera dina annonsinställningar
          </a>
        </p>

        <h3>Om Schibsted Media och annonsering</h3>

        <p>
          Schibsted Media är en etablerad mediekoncern med huvudkontor i Oslo,
          som äger sajter som till exempel Omni, Svenska Dagbladet, Aftonbladet
          och Tv.nu.
        </p>

        <p>
          <a
            href="https://info.privacy.schibsted.com/se/smarta-annonser-och-vad-de-innebar-for-dig/"
            className="modal-cogwheel-link a"
            target="_blank"
            rel="noreferrer"
          >
            Läs mer om annonsering på Schibsted Media
          </a>
        </p>

        <p className="modal-cogwheel-footer">
          Omni är en del av{" "}
          <a
            href="http://schibsted.com/"
            className="modal-cogwheel-footer-link"
            target="_blank"
            rel="noreferrer"
          >
            Schibsted Media
          </a>
          {creativeIdComponent}
        </p>
      </div>
    </div>
  );
};

export { CogwheelModal };
